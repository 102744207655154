


/*** container ****/

var EventDispatcher = (function () {
    function EventDispatcher() {
        this.events = {};

    }


    EventDispatcher.prototype.create = function (event) {
        Object.assign(event, {
            __stop: false,
            stop: function () {
                this.__stop = true;
            },
            play: function () {
                this.__stop = false;
            },
            isStop: function () {
                return this.__stop;
            }
        });
    };
    EventDispatcher.prototype.dispatch = function (name, event) {
        this.create(event);
        Object.assign(event, {
            __stop: false,
            stop: function () {
                this.__stop = true;
            },
            play: function () {
                this.__stop = false;
            },
            isStop: function () {
                return this.__stop;
            }
        });
        if (!this.events[name]) {
            return;
        }
        this.events[name].forEach(function (val) {

            if (event.isStop()) {
                return;
            }
            val.callback(event);
        });
        return event.isStop();
    };
    EventDispatcher.prototype.on = function (name, callback, options) {
        if (!options) {
            options = {};
        }
        if (!this.events[name]) {
            this.events[name] = [];
        }
        options = Object.assign({ priority: 1000000 }, options);
        var EVENT_ID = Math.round(Math.random() * 1000000000);
        this.events[name].push({ callback: callback, event_id: EVENT_ID, options: options });

        this.events[name] = this.events[name]
            .filter(function () {
                return true;
            })
            .sort(function (a, b) {
                return a.options.priority > b.options.priority ? 1 : -1;
            });


        return EVENT_ID;
    };
    EventDispatcher.prototype.off = function (name, event_id) {
        if (!this.events[name]) {
            return;
        }
        for (var i = 0; i < this.events[name].length; i++) {
            if (this.events[name][i].event_id === event_id) {
                this.events[name].splice(i, 1);
                return true;
            }
        }
        return false;
    }


    return EventDispatcher;
})();



var Container = (function () {
    function Container() {
        this.options = {};
        this.services = {};
        this.session = {};
        this.m_init = false;
    }

    Container.prototype.register = function (name, handler) {
        this.services[name] = handler;
        return this;
    };
    Container.prototype.get = function (name) {
        return this.services[name] ? this.services[name] : null;
    };
    Container.prototype.has = function (name) {
        return this.services[name] ? true : false;
    };



    Container.prototype.init = function (options, callback) {


        Object.assign(this.options, options);
        if (!document || !document.body || !document.body.append) {

            return callback(true);
        }

        var session = {};



        if (window.SESSION) {
            for (var service_name in this.services) {
                if (this.services[service_name].init) {
                    this.services[service_name].init(this.options, function () {
                    }, session);
                }

            }
            this.session = window.SESSION;
            this.get('event.dispatcher').on('session', function (s) {
            });
            this.get('event.dispatcher').dispatch('session', this.session);
            callback(true);
            return;
        }


        var total = 0;
        for (var service_name in this.services) {
            if (this.services[service_name].init) {
                total++;
            }
        }
        var load = 0;
        var that = this;

        var next = function () {
            if (Object.keys(session).length > 0) {

                // var args = [];
                // for (var name in session) {
                //     args.push(name + '=' + encodeURI(session[name]));
                // }
                options['http'](options['url'] + 'session.js', session, function (r) { return r; }).subscribe(function (SESSION) {
                    that.session = SESSION;
                    that.get('event.dispatcher').dispatch('session', SESSION);
                    callback(true);
                }, function () {
                    callback(false);
                });

                // var script = document.createElement("script");
                // script.src = options['url'] + "session.js?" + args.join('&');
                // script.onload = function () {
                //     that.session = SESSION;
                //     that.get('event.dispatcher').dispatch('session', SESSION);
                //     document.body.removeChild(script);
                //     callback(true);

                // }
                // script.onerror = function () {
                //     callback(false);

                // }
                // document.body.append(script);

            } else {
                callback(true);
            }
        }

        for (var service_name in this.services) {
            if (this.services[service_name].init) {
                this.services[service_name].init(this.options, function () {
                    load++;
                    if (total === load) {
                        next();
                    }
                }, session);
            }

        }
    }


    Container.prototype.addOption = function (key, value) {
        return this.options[key] = value;
    };


    return Container;
})();



var containerjs = new Container();
window['containerjs'] = containerjs;
containerjs.register('event.dispatcher', new EventDispatcher());





(function(containerjs){ try {
 /*** form.builder ****/

var FORM_BUILDER_TYPE = {
    TextType: 'text',
    PasswordType: 'password',
    CheckboxType: 'checkbox',
    SelectType: 'select',
    LabelType: 'label',
    RadioType: 'radio',
    GUIDType: 'guid',
    DateType: 'date',
    TimeType: 'time',
    PhoneType: 'phone',
    PriceType: 'price',
    EntityType: 'entity',
    NumberType: 'number',
    NumberFormatType: 'number_format',
    DateTimeType: 'datetime',
    ButtonType: 'button',
    ActionType: 'action',
    TextareaType: 'textarea',
    HTMLType: 'html',
    StyleType: 'style',
    BlockType: 'block',
    FlexType: 'flex',
    DynamicType: 'dynamic',
    PageType: 'page',
    RowType: 'row',
    ColType: 'col',
    PassportType: 'passport',
    SNILSType: 'snils',
    INNType: 'inn',
    CollectionType: 'collection',
    GroupType: 'group',
    DivType: 'div',
    TemplateType: 'template'
};

var FormBuilder = (function () {
    function FormBuilder() {
        this.cache = {};
        this.api_send_list = {};
        this.api_result_cache = {};
    }

    FormBuilder.prototype.load = function (package_name, form_name) {
        if (containerjs.has('api')) {
            var that = this;
            return containerjs.get('api').send('form.builder/load', { package_name: package_name, form_name: form_name }, { responseType: 'text' }, function (form) {
                if (form.error) {
                    return form;
                }
                var form = form;
                var exports;
                eval(form);
                // that.cache[package_name + '.' + form_name] = exports;
                return exports;
            });
        }
    };

    FormBuilder.prototype.createForm = function (values) {
        values = values || {};
        return {
            TYPE: FORM_BUILDER_TYPE,
            values: values,
            style: {},
            fields: [],
            pages: [],
            setValues: function (values) {
                this.values = Object.assign(this.values, values);
            },
            getValues: function () {
                return this.values;
            },
            setStyle: function (style) {
                this.style = style;
                return this;
            },
            clear: function () {
                for (var i = 0; i < this.fields.length; i++) {
                    const field = this.fields[i];
                    if (field.builder && field.builder.destroy) {
                        field.builder.destroy(true);
                        field.builder = null;
                    }
                }
                this.fields = [];
            },
            destroy: function (sub) {
                for (var i = 0; i < this.fields.length; i++) {
                    const field = this.fields[i];
                    if (field.builder && field.builder.destroy) {
                        field.builder.destroy(true);
                        field.builder = null;
                    }

                    // field = null;
                }
                // this.values = null;
                this.style = {};
                if (!sub) {
                    this.fields = [];
                }
            },
            getStyle: function (key) {
                return key ? this.style[key] : this.style;
            },
            add: function (name, field_type, args, callback) {
                var that = this;
                args = args || {};
                switch (field_type) {
                    // case this.EntityType:

                    //     break;
                    case FORM_BUILDER_TYPE.GroupType:
                        //args.form = args.target.getForm();
                        args.builder = args.target;
                        delete args.target;
                        break;
                }
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                args.disabled =
                    args.disabled ||
                    function () {
                        return false;
                    };
                if (field_type === FORM_BUILDER_TYPE.EntityType) {
                    if (!this.values[name] || Array.isArray(this.values[name])) {
                        this.values[name] = {};
                    }
                    var builder = this.createForm(this.values[name]);

                    callback.call(builder, builder);
                    this.fields.push(Object.assign({ name: name, field_type: field_type, builder: builder }, args));
                    return this;
                }

                if (field_type === FORM_BUILDER_TYPE.CollectionType) {
                    if (!this.values[name]) {
                        this.values[name] = [];
                    }
                    var builder = this.createForm(this.values[name]);

                    callback.call(builder, builder);
                    this.fields.push(Object.assign({ name: name, field_type: field_type, builder: builder }, args));
                    return this;
                }
                var field = Object.assign({ name: name, field_type: field_type, value: null }, args);
                args.init ? args.init(field) : null;
                this.fields.push(field);

                if (args.method) {
                    var that = this;
                    (function (field, builder) {
                        var key = field.method + '/' + JSON.stringify(field.method_args || {});
                        if (builder.api_result_cache[key]) {
                            //TODO: only if cache args is set in add field
                            field.source = builder.api_result_cache[key];
                            return;
                        }
                        field.preloader = true;

                        var send = false;
                        if (!builder.api_send_list[key]) {
                            builder.api_send_list[key] = [];
                            builder.api_send_list[key].push(field);
                            send = true;
                        } else {
                            builder.api_send_list[key].push(field);
                            return;
                        }

                        if (send) {


                            // field.sourceAsync = containerjs.get('api').send(field.method, field.method_args || {});

                            containerjs
                                .get('api')
                                .send(field.method, field.method_args || {})
                                .subscribe(
                                    function (result) {
                                        builder.api_result_cache[key] = result;
                                        for (var i = 0; i < builder.api_send_list[key].length; i++) {
                                            builder.api_send_list[key][i].source = result; //builder.api_result_cache[key];
                                            builder.api_send_list[key][i].async = true;
                                            builder.api_send_list[key][i].preloader = false;
                                        }
                                        delete builder.api_send_list[key];
                                    },
                                    function (error) {
                                        for (var i = 0; i < builder.api_send_list[key].length; i++) {
                                            builder.api_send_list[key][i].preloader = false;
                                            builder.api_send_list[key][i].error_source = error;
                                        }
                                    }
                                );
                        }
                    })(field, containerjs.get('form.builder'));
                }

                Object.defineProperty(field, 'value', {
                    get: function () {
                        return Object.keys(that.values).indexOf(field.name) > -1 ? that.values[field.name] : null;
                    },
                    set: function (value) {
                        if (field.change) {
                            field.change(field, that);
                        }
                        if (Object.keys(that.values).indexOf(field.name) === -1 && !value) {
                            return;
                        }
                        that.values[field.name] = value;
                    }
                });
                return this;
            },
            setPages: function (list) {
                list.forEach(function (p) {
                    p.if =
                        p.if ||
                        function () {
                            return true;
                        };
                });
                this.pages = list;
                return this;
            },
            page: function (name, callback, args) {
                var builder = this.createForm(this.values);
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                callback.call(builder, builder);
                this.fields.push(Object.assign({ field_type: FORM_BUILDER_TYPE.PageType, /*form: builder.getForm(),*/ builder: builder, name: name }, args));
                return this;
            },

            dynamic: function (callback, args) {
                // var builder = this.createForm(this.values);
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                args.callback = callback;
                // callback.call(builder, builder);
                this.fields.push(Object.assign({ field_type: FORM_BUILDER_TYPE.DynamicType }, args));
                return this;
            },
            div: function (callback, args) {
                var builder = this.createForm(this.values);
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                callback.call(builder, builder);
                this.fields.push(Object.assign({ field_type: FORM_BUILDER_TYPE.DivType, /*form: builder.getForm(),*/ builder: builder }, args));
                return this;
            },
            template: function (name, args) {
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                this.fields.push(Object.assign({ field_type: FORM_BUILDER_TYPE.TemplateType, /*form: builder.getForm(),*/ name: name }, args));
                return this;
            },
            flex: function (callback, args) {
                var builder = this.createForm(this.values);
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                callback.call(builder, builder);
                this.fields.push(Object.assign({ field_type: FORM_BUILDER_TYPE.FlexType, /*form: builder.getForm(),*/ builder: builder }, args));
                return this;
            },

            block: function (callback, args) {
                var builder = this.createForm(this.values);
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                callback.call(builder, builder);
                this.fields.push(Object.assign({ field_type: FORM_BUILDER_TYPE.BlockType, /*form: builder.getForm(),*/ builder: builder }, args));
                return this;
            },
            action: function (callback, args) {
                var builder = this.createForm(this.values);
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                callback.call(builder, builder);
                this.fields.push(Object.assign({ field_type: FORM_BUILDER_TYPE.ActionType, /*form: builder.getForm(),*/ builder: builder }, args));
                return this;
            },

            label: function (label, args) {
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                var field = Object.assign({ field_type: FORM_BUILDER_TYPE.LabelType, label: label }, args);
                args.init ? args.init(field) : null;
                this.fields.push(field);
                return this;
            },
            html: function (callback, args) {
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                var field = Object.assign({ field_type: FORM_BUILDER_TYPE.HTMLType, callback: callback }, args);
                args.init ? args.init(field) : null;
                this.fields.push(field);
                return this;
            },
            addStyle: function (style, args) {
                args = args || {};
                args.if =
                    args.if ||
                    function () {
                        return true;
                    };
                var field = Object.assign({ field_type: FORM_BUILDER_TYPE.StyleType, style: style }, args);
                args.init ? args.init(field) : null;
                this.fields.push(field);
                return this;
            },

            button: function (name, args) {
                // args = args || {};
                // args.if = args.if || function () {
                //     return true;
                // };
                // var field = Object.assign({ type: this.ButtonType, name: name }, args);
                // this.fields.push(field);
                this.add(
                    name,
                    FORM_BUILDER_TYPE.ButtonType,
                    Object.assign(
                        {
                            type: name === 'submit' ? 'raised' : 'default',
                            color: name === 'submit' ? 'accent' : 'default',
                            class: name === 'submit' ? 'btn-accent' : name === 'cancel' ? 'btn-default' : ''
                        },
                        args
                    )
                );
                return this;
            },
            /*row: function (callback, args) {
                var builder = this.createForm();
                callback(builder);
                this.fields.push(Object.assign({ type: this.RowType, form: builder.getForm() }, args || {}));
                return this;

            },
            col: function (callback, args) {
                var builder = this.createForm();
                callback(builder);
                this.fields.push(Object.assign({ type: this.ColType, form: builder.getForm() }, args || {}));
                return this;

            },*/
            getForm: function () {
                return this.fields;
            },
            getAction: function () {
                var actions = [];
                for (var i = 0; i < this.fields.length; i++) {
                    if (this.fields[i].field_type === FORM_BUILDER_TYPE.ActionType) {
                        actions.push(this.fields[i]);
                    }
                }
                return actions;
            },
            createForm: function (values) {
                return containerjs.get('form.builder').createForm(values);
            }
        };
    };

    return FormBuilder;
})();

containerjs.register('form.builder', new FormBuilder());
containerjs.register('form.builder.type', FORM_BUILDER_TYPE);

} catch(e) {console.log(e)} })(containerjs);